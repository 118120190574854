import React from "react"
import ContactUs from "../components/ContactUs/ContactUs"

import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
export default function Contact() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/contact" />
      </Helmet>
      <ContactUs queries />
    </Layout>
  )
}
